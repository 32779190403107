<template lang="pug">
	section#contactBox
		.verde
			img(src="@images/paginas/fale-conosco/icons/celular.png").ligueCel
			.numbers
				span(v-for="item in contents.content").ligueVerde {{ item }}
</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: "section-contact-box",
	props,
}
</script>

<style lang="stylus" scoped src="./ContactBox.styl"></style>
